<!DOCTYPE html>
<html lang="en">
  <head>
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
  </head>
  <body>
    <mat-dialog-content>
      <form #form="ngForm" (ngSubmit)="onSubmit()">
        <div class="modal-body">
          <h4 class="dialog-title">Schedule your demo now!</h4>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <input
                  [(ngModel)]="demo.firstName"
                  #name="ngModel"
                  maxlength="200"
                  name="firstName"
                  type="text"
                  class="form-control"
                  placeholder="First name*"
                  required
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input
                  [(ngModel)]="demo.lastName"
                  name="lastName"
                  type="text"
                  maxlength="200"
                  class="form-control"
                  placeholder="Last name*"
                  required
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input
                  [(ngModel)]="demo.organization"
                  name="organization"
                  maxlength="200"
                  type="text"
                  class="form-control"
                  placeholder="Organization*"
                  required
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input
                  [(ngModel)]="demo.jobTitle"
                  name="jobTitle"
                  maxlength="200"
                  type="text"
                  class="form-control"
                  placeholder="Job title*"
                  required
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input
                  [(ngModel)]="demo.phoneNumber"
                  name="phoneNumber"
                  type="text"
                  maxlength="200"
                  class="form-control"
                  placeholder="Phone number*"
                  required
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input
                  name="email"
                  [(ngModel)]="demo.email"
                  type="text"
                  maxlength="200"
                  class="form-control"
                  placeholder="Email*"
                  required
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <select
                  [(ngModel)]="demo.country"
                  name="country"
                  id="country"
                  placeholder="Country"
                  class="form-control browser-default custom-select"
                >
                  <option value="Hungary">Hungary</option>
                </select>
              </div>
            </div>
          </div>
          <div class="form-group">
            <textarea
              [(ngModel)]="demo.message"
              name="message"
              maxlength="5000"
              id="message"
              class="form-control"
              placeholder="Message"
            ></textarea>
          </div>
          <mat-dialog-actions align="center">
            <button
              [disabled]="form.invalid"
              name="send"
              type="submit"
              class="btn btn-primary btn-rounded btn-lg waves-effect waves-light"
            >
              <i class="fas fa-play mr-3 text-primary"></i>Request demo
            </button>
          </mat-dialog-actions>
        </div>
      </form>
    </mat-dialog-content>
  </body>
</html>
