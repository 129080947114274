<!DOCTYPE html>
<html lang="en">
  <head>
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
  </head>

  <body aria-busy="true" cz-shortcut-listen="true">
    <div style="display: flex">
      <div
        style="
          display: inline-block;
          width: 10%;
          top: 50%;
          position: absolute;
          text-align: center;
        "
      >
        <h1 style="font-weight: bold">404</h1>
        <h4 style="font-weight: bold">Not found</h4>
        <div class="text-center">
          <button
            routerLink="/"
            name="send"
            type="button"
            class="btn btn-success btn-rounded btn-lg"
          >
            Back to home
          </button>
        </div>
      </div>
      <div style="width: 90%">
        <img src="../../assets/img/not-found.svg" alt="" />
      </div>
    </div>
  </body>
</html>
