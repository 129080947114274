<!DOCTYPE html>
<html lang="en">
  <head></head>
  <body aria-busy="true" cz-shortcut-listen="true" style="
   position: fixed;
    width: 100%;
    z-index: 10000000;">
    <!--Navbar-->
    <nav
      class="navbar navbar-expand-lg navbar-dark justify-content-between align-items-center"
    >
      <div class="container">
        <div class="d-flex w-100 align-items-center justify-content-between">
          <div class="">
            <div class="order-1">
              <a class="navbar-brand" routerLink="/">
                <!--<img src="../img/openup/logo.svg" height="30" alt="Openup logo">-->
                <img
                  src="../../assets/img/logo.svg"
                  height="30"
                  alt="PTE logo"
                />
                <span class="logo-text">Openup</span
                ><span class="text-success">.</span>
              </a>
            </div>
          </div>
          <button
            class="navbar-toggler text-left"
            id="nav-icon"
            type="button"
            (click)="toggleNavbar()"
          >
            <span class="navbar-icon-bar"></span>
            <span class="navbar-icon-bar"></span>
            <span class="navbar-icon-bar"></span>
            <span class="navbar-icon-bar"></span>
          </button>
          <div
            class="collapse navbar-collapse"

          >
            <div class="d-flex align-items-center">
              <ul class="list-unstyled d-flex mb-0 mr-5">
                <ng-container >
                  <li>
                    <a
                      (click)="scroll('home')"

                      class="font-default text-white font-weight-bold mr-4"
                      >Home</a
                    >
                  </li>
                </ng-container>
                <li>
                  <a
                    (click)="scroll('functions')"
                    class="font-default text-white font-weight-bold mr-4"
                    >Functions</a
                  >
                </li>
                <li>
                  <a
                    (click)="scroll('tutorials')"
                    class="font-default text-white font-weight-bold mr-4"
                  >Tutorials</a
                  >
                </li>
                <li>
                  <a
                    (click)="scroll('applications')"
                    class="font-default text-white font-weight-bold mr-4"
                  >Applications</a
                  >
                </li>
                <li>
                  <a
                    (click)="scroll('aboutus')"
                    class="font-default text-white font-weight-bold mr-4"
                  >About us</a
                  >
                </li>
                <!--<li><a href="customers.html" class="font-default text-white font-weight-bold mr-4">Customers</a></li>-->
              </ul>
              <div>
                <a
                  (click)="openDialog()"
                  class="btn btn-primary btn-rounded mr-3 waves-effect waves-light"
                  >Request Demo</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </body>
</html>
