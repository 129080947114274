import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { Blog } from '../blog-entry/model/blog';
import { BlogService } from './blog.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css'],
})
export class BlogComponent implements OnInit {
  $blogs: Observable<Array<Blog>>;

  constructor(private service: BlogService, private titleService: Title) {}

  ngOnInit(): void {
    this.$blogs = this.service.getBlogs();
    this.titleService.setTitle('Blog');
  }
}
