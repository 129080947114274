import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Blog } from '../blog-entry/model/blog';
import { Endpoints } from '../endpoints';

@Injectable({
  providedIn: 'root',
})
export class BlogService {
  constructor(private httpClient: HttpClient) {}

  getBlogs(): Observable<Array<Blog>> {
    return this.httpClient.get<Array<Blog>>(Endpoints.BLOG);
  }
}
