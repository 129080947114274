import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {ViewportScroller} from '@angular/common';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {DemoDialogComponent} from '../demo-dialog/demo-dialog.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  navbarOpen = false;

  currentUrl: string;
  urlSub$: Subscription;

  constructor(private router: Router, private scroller: ViewportScroller, public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.currentUrl = this.router.url;
    this.urlSub$ = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
      }
    });
  }

  ngOnDestroy(): void {
    this.urlSub$.unsubscribe();
  }

  scroll(element: string): void {
    this.scroller.setOffset([40, 40]);
    this.scroller.scrollToAnchor(element);
  }

  toggleNavbar(): void {
    this.navbarOpen = !this.navbarOpen;
  }

  closeNavbar(): void {
    this.navbarOpen = false;
  }

  openDialog(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    this.dialog.open(DemoDialogComponent, dialogConfig);
  }
}
