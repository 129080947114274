import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Product } from './model/product';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css'],
})
export class ProductComponent implements OnInit {
  products: Array<Product>;
  constructor(private titleService: Title) {}

  ngOnInit(): void {
    this.titleService.setTitle('Product');
    this.products = [
      new Product(
        'Feed',
        'Provide users access to their vibrant community. Search' +
          'members based on skills, fields of expertise, institution' +
          'and many more and interact instantly.'
      ),
      new Product(
        'Members',
        'Provide users access to their vibrant community. Search' +
          'members based on skills, fields of expertise, institution' +
          'and many more and interact instantly.'
      ),
      new Product(
        'Mentoring',
        'A space for mentors and mentees to engage. Users can offer' +
          'or find opportunities while intelligent search mechanisms' +
          'ensure an ideal match.'
      ),
      new Product(
        'Jobs',
        'A focused area to post or find internship and job' +
          'opportunities in this vibrant online community.'
      ),
      new Product(
        'Projects',
        'A complex repository of entrepreneurial projects. Find,' +
          'create and join co-working spaces and get access to an' +
          'interactive project development hub.'
      ),
      new Product(
        'Groups',
        'Allow emerging sub-communities to create spaces on the' +
          'platform for focused interaction.'
      ),
      new Product(
        'Events',
        'From alumni meetups to pitch competitions,' +
          ' manage event promotion and registration in one streamlined place.'
      ),
    ];
  }
}
