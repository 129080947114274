<!DOCTYPE html>
<html lang="en">
  <head></head>
  <body aria-busy="true" cz-shortcut-listen="true">
    <section class="subpage-hero resources-hero">
      <div class="container">
        <img src="../../assets/img/home3.png" />
        <div class="row">
          <div class="col-lg-7 offset-lg-5">
            <h1>
              Openup.education is here to support your journey towards a vibrant
              entrepreneurial university.
            </h1>
            <p>
              Aside from the platform, you gain access to various types of
              resources and educational tools helping you to understand,
              navigate and improve your ecosystem.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="resources">
      <div class="container video-content">
        <div class="row">
          <div class="col-lg-4">
            <iframe
              style="margin-left: auto; margin-right: auto;margin-top: 50px"
              width="280"
              src="https://www.youtube.com/embed/r08jpv0fWsY"
              height="158"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div class="col-lg-4">
            <iframe
              style="margin-left: auto; margin-right: auto;margin-top: 50px"
              width="280"
              src="https://www.youtube.com/embed/Ln2AZNi--Xc"
              height="158"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div class="col-lg-4">
            <iframe
              style="margin-left: auto; margin-right: auto;margin-top: 50px"
              width="280"
              src="https://www.youtube.com/embed/gQe74jFyVCI"
              height="158"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div class="col-lg-4">
            <iframe
              style="margin-left: auto; margin-right: auto;margin-top: 50px"
              width="280"
              src="https://www.youtube.com/embed/9jMExS7Zi4E"
              height="158"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div class="col-lg-4">
            <iframe
              style="margin-left: auto; margin-right: auto;margin-top: 50px"
              width="280"
              src="https://www.youtube.com/embed/BYHmtzy147Y"
              height="158"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div class="col-lg-4">
            <iframe
              style="margin-left: auto; margin-right: auto;margin-top: 50px"
              width="280"
              src="https://www.youtube.com/embed/uCHzi_je43w"
              height="158"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div class="col-lg-4">
            <iframe
              style="margin-left: auto; margin-right: auto;margin-top: 50px"
              width="280"
              src="https://www.youtube.com/embed/GSSU3-TnosA"
              height="158"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div class="col-lg-4">
            <iframe
              style="margin-left: auto; margin-right: auto;margin-top: 50px"
              width="280"
              src="https://www.youtube.com/embed/4LUaLtlnTqU"
              height="158"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div class="col-lg-4">
            <iframe
              style="margin-left: auto; margin-right: auto;margin-top: 50px"
              width="280"
              src="https://www.youtube.com/embed/5ta4YS3ptvc"
              height="158"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div class="col-lg-4">
            <iframe
              style="margin-left: auto; margin-right: auto;margin-top: 50px"
              width="280"
              src="https://www.youtube.com/embed/_GuIMfBs3M4"
              height="158"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div class="col-lg-4">
            <iframe
              style="margin-left: auto; margin-right: auto;margin-top: 50px"
              width="280"
              src="https://www.youtube.com/embed/Ww174FUBNFo"
              height="158"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div class="col-lg-4">
            <iframe
              style="margin-left: auto; margin-right: auto;margin-top: 50px"
              width="280"
              src="https://www.youtube.com/embed/a4mMTJ1tzmc"
              height="158"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div class="col-lg-4">
            <iframe
              style="margin-left: auto; margin-right: auto;margin-top: 50px"
              width="280"
              src="https://www.youtube.com/embed/-NI8TLHALQ0"
              height="158"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <!--<div class="row">
          <div
            class="col-lg-12"
            *ngFor="let openup of $openupTutorials | async"
          >
            <div class="card mb-6">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div class="card-icon">
                    <img src="../../assets/img/career.svg" />
                  </div>
                  <div>
                    <h4>{{ openup.title }}</h4>
                    <p>{{ openup.description }}</p>
                    <div class="display-on-mobile">
                      <a href="{{ openup.youtubeVideoUrl }}">Click here</a>
                    </div>
                  </div>
                </div>
                <div class="d-flex display-not-mobile">
                  <iframe
                    style="margin-left: auto; margin-right: auto"
                    width="280"
                    [src]="openup.youtubeEmbedUrl | safe"
                    height="158"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-12">
            <div class="card mb-6">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div>
                    <h1>Openup Submodule: SkillVersum</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-lg-12"
            *ngFor="let sv of $skillversumTutorials | async"
          >
            <div class="card mb-6">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div class="card-icon">
                    <img src="../../assets/img/career.svg" alt="" />
                  </div>
                  <div>
                    <h4>{{ sv.title }}</h4>
                    <p>{{ sv.description }}</p>
                    <div class="display-on-mobile">
                      <a href="{{ sv.youtubeVideoUrl }}">Click here</a>
                    </div>
                  </div>
                </div>
                <div class="d-flex display-not-mobile">
                  <iframe
                    style="margin-left: auto; margin-right: auto"
                    width="280"
                    [src]="sv.youtubeEmbedUrl | safe"
                    height="158"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>-->
      </div>
    </section>
  </body>
</html>
