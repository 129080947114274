<!DOCTYPE html>
<html lang="en">
  <head>
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
  </head>
  <body aria-busy="true" cz-shortcut-listen="true">
    <footer>
      <div class="container">
        <div class="d-flex align-items-center flex-wrap">
          <img src="../../assets/img/logo.svg" alt="PTE logo" class="mr-4" />
        </div>
        <div
          class="d-flex align-items-center flex-wrap font-xs justify-content-between"
        >
          <div class="d-flex align-items-center">
            <ul class="list-unstyled d-flex mb-0">
              <li class="mr-4">© 2023 Openup</li>
              <li>
                <a href="https://openup.education/about.html"
                  >Privacy Policy and Notice</a
                >
              </li>
            </ul>
          </div>
          <div class="mt-3 mt-lg-0">
            <ul class="list-unstyled d-flex mb-0 flex-wrap">
              <li class="mb-2 mb-md-0 mr-5">
                <a href="tel:++36209725080">
                  <i class="fas fa-phone-alt mr-2"></i>
                  +36 20 972 5080
                </a>
              </li>
              <li class="mb-2 mb-md-0 mr-5">
                <a href="mailto:info@openup.education">
                  <i class="fas fa-envelope mr-2"></i>
                  info@openup.education
                </a>
              </li>
              <li>
                <a
                  href="https://www.google.com/maps/place/P%C3%A9cs,+Vasv%C3%A1ri+P%C3%A1l+u.+4,+7622/@46.0735924,18.2362097,17z/data=!3m1!4b1!4m5!3m4!1s0x4742b174ad6205bb:0x4ac80e7dd30ff84e!8m2!3d46.0735924!4d18.2383984"
                  target="_blank"
                >
                  <i class="fas fa-map-marker-alt mr-2"></i>
                  H-7622 Pécs Vasvári Pál utca 4.
                </a>
              </li>
            </ul>
          </div>
          <div class="d-flex align-items-center mt-3 mt-xl-0">
            <span class="mr-4">Follow us on social media!</span>
            <ul class="list-unstyled d-flex mb-0 align-items-center social">
              <li class="mr-4">
                <a href="https://www.facebook.com/openup.education/"
                  ><i class="fab fa-facebook-square"></i
                ></a>
              </li>
              <li class="mr-4">
                <a href="https://www.linkedin.com/company/openup-education/"
                  ><i class="fab fa-linkedin"></i
                ></a>
              </li>
              <li class="mr-4">
                <a href="https://www.instagram.com/openup.education/"
                  ><i class="fab fa-instagram"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </body>
</html>
