import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { BlogEntryService } from './blog-entry.service';
import { Blog } from './model/blog';

@Component({
  selector: 'app-blog-entry',
  templateUrl: './blog-entry.component.html',
  styleUrls: ['./blog-entry.component.css'],
})
export class BlogEntryComponent implements OnInit, OnDestroy {
  $blogEntrySubscription: Subscription;
  blogEntry: Blog;

  constructor(
    private blogEntryService: BlogEntryService,
    private route: ActivatedRoute,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    let url = this.route.snapshot.paramMap.get('url');
    this.blogEntryService.getBlogByUrl(url).subscribe((blog) => {
      (this.blogEntry = blog), this.titleService.setTitle(this.blogEntry.title);
    });
  }

  ngOnDestroy(): void {
    this.$blogEntrySubscription.unsubscribe();
  }
}
