<!DOCTYPE html>
<html lang="en">
  <head></head>
  <body aria-busy="true" cz-shortcut-listen="true">
    <section class="subpage-hero resources-hero">
      <div class="container">
        <img src="../../assets/img/home3.png" alt="" />
        <div class="row">
          <div class="col-lg-7 offset-lg-5">
            <h1>
              Openup.education is here to support your journey towards a vibrant
              entrepreneurial university.
            </h1>
            <p>
              Aside from the platform, you gain access to various types of
              resources and educational tools helping you to understand,
              navigate and improve your ecosystem.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="resources">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="card mb-4">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div class="card-icon pointer">
                    <img src="../../assets/img/test.svg" alt="" />
                  </div>
                  <div>
                    <h4 class="pointer">Self-assessment Tool</h4>
                    <p>
                      Evaluate the current status of your entrepreneurial
                      ecosystem, get professional guidance on how and what to
                      improve.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card mb-4">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div class="card-icon pointer">
                    <img src="../../assets/img/career.svg" alt="" />
                  </div>
                  <div>
                    <h4 class="pointer">Case Studies</h4>
                    <p>
                      Gain insights and learn about best practices of our
                      customers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card mb-4">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div class="card-icon pointer">
                    <img
                      src="../../assets/img/webcam.svg"
                      alt=""
                      routerLink="/tutorial"
                    />
                  </div>
                  <div>
                    <h4 routerLink="/tutorial" class="pointer">Videos</h4>
                    <p>
                      Watch tutorials, short courses and interviews to enhance
                      your Openup.education experience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card mb-4">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div class="card-icon pointer">
                    <img
                      src="../../assets/img/news.svg"
                      alt=""
                      routerLink="/blog"
                    />
                  </div>
                  <div>
                    <h4 routerLink="/blog" class="pointer">Blog</h4>
                    <p>
                      Read first-hand about updates, improvements and topics
                      surrounding Openup.education, university innovation and
                      entrepreneurship.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </body>
</html>
