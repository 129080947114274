<!DOCTYPE html>
<html lang="en">
  <head>
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
  </head>
  <body aria-busy="true" cz-shortcut-listen="true">
    <section class="about-us">
      <div class="container">
        <h2 class="subtitle text-center">About us</h2>
        <h1 class="text-center">
          Want to learn more <br />
          about Openup.education?
        </h1>
        <p class="text-center">
          We would be happy to hear from you. Contact us via the details below
          or schedule a demo and let one of our colleagues guide you through the
          platform.
        </p>
        <div class="text-center">
          <a
            (click)="openDialog()"
            class="btn btn-outline-primary btn-rounded btn-lg waves-effect waves-light"
            ><i class="fas fa-play mr-3 text-primary"></i>Request Demo</a
          >
        </div>
      </div>
    </section>

    <section class="contact-us">
      <div class="container">
        <h2 class="subtitle text-center">Contact us</h2>
        <div class="d-flex justify-content-center">
          <ul class="list-unstyled mb-0">
            <li>
              <a href="tel:+36209725080"
                ><i class="fas fa-phone-alt mr-3"></i>+36 20 972 5080</a
              >
            </li>
            <li>
              <a href="mailto:info@openup.education">
                <i class="fas fa-envelope mr-3"></i>
                info@openup.education
              </a>
            </li>
            <li>
              <a
                href="https://www.google.com/maps/place/P%C3%A9cs,+Vasv%C3%A1ri+P%C3%A1l+u.+4,+7622/@46.0735924,18.2362097,17z/data=!3m1!4b1!4m5!3m4!1s0x4742b174ad6205bb:0x4ac80e7dd30ff84e!8m2!3d46.0735924!4d18.2383984"
                ><i class="fas fa-map-marker-alt mr-3"></i>1 H-7622 Pécs Vasvári
                Pál utca 4.</a
              >
            </li>
          </ul>
        </div>
      </div>
    </section>

    <section class="socials">
      <div class="container">
        <div class="d-flex flex-column align-items-center">
          <p>Follow us on social media!</p>
          <ul class="list-unstyled d-flex mb-0 align-items-center">
            <li class="mr-4">
              <a href="https://www.facebook.com/openup.education/"
                ><i class="fab fa-facebook-square"></i
              ></a>
            </li>
            <li class="mr-4">
              <a href="https://www.linkedin.com/company/openup-education/"
                ><i class="fab fa-linkedin"></i
              ></a>
            </li>
            <li>
              <a href="https://www.instagram.com/openup.education/"
                ><i class="fab fa-instagram"></i
              ></a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </body>
</html>
