<!DOCTYPE html>
<html lang="en">
  <head></head>
  <body aria-busy="true" cz-shortcut-listen="true">
    <section class="resources">
      <div class="container">
        <div class="row" *ngFor="let blog of $blogs | async">
          <div class="col-lg-12">
            <div class="card mb-6">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div>
                    <h4>{{ blog.title }}</h4>
                    <p>{{ blog.description }}</p>
                  </div>
                </div>
                <div class="d-flex">
                  <img
                    style="margin-left: auto; margin-right: auto"
                    src="{{ blog.thumbnailImage }}"
                    class="img-fluid"
                  />
                </div>
                <div class="d-flex align-items-center">
                  <div>
                    <a
                      routerLink="/blog/{{ blog.url }}"
                      class="btn btn-primary btn-rounded mr-3 waves-effect waves-light"
                      >Open</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </body>
</html>
