<!DOCTYPE html>
<html lang="en">
  <head>
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
  </head>

  <body aria-busy="true" cz-shortcut-listen="true">
    <section class="subpage-hero product-hero">
      <img src="../../assets/img/home1.png" alt="" />
      <div class="container">
        <div class="row">
          <div class="col-lg-6 offset-lg-5">
            <h1>
              Unlock the full potential <br />
              lying in the entrepreneurship <br />
              ecosystem of your institution!
            </h1>
            <p>
              <span>Openup.education</span> - a focused platform solution
              catering for your university innovation and student
              entrepreneurship needs - facilitates fruitful connections between
              actors of your ecosystem, inspires and catalyses innovative
              entrepreneurial collaborations and supports projects in turning
              their concepts into real businesses through digital co-working
              spaces and interactive learning materials.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="benefits">
      <div class="container">
        <h2 class="subtitle text-center">Benefits</h2>

        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div class="card">
              <div class="card-body">
                <div class="card-icon">
                  <img src="../../assets/img/teamwork.svg" alt="" />
                </div>
                <h3>Catalysing entrepreneurial collaborations</h3>
                <p>
                  Engage your community, facilitate inspiring connections and
                  see entrepreneurial projects unfold.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="card">
              <div class="card-body">
                <div class="card-icon">
                  <img src="../../assets/img/group.svg" alt="" />
                </div>
                <h3>Connecting students to real-life opportunities</h3>
                <p>
                  Allow students to navigate internship and job opportunities,
                  find and participate in company projects, network with company
                  representatives or even receive one-on-one mentoring.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="card">
              <div class="card-body">
                <div class="card-icon">
                  <img src="../../assets/img/deal.svg" alt="" />
                </div>
                <h3>Enabling practitioners to take part in education</h3>
                <p>
                  Enhance your institution’s company relationships through
                  providing a platform for your partners to meaningfully engage
                  with a driven student body by taking an active role in their
                  training and education.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="card">
              <div class="card-body">
                <div class="card-icon">
                  <img src="../../assets/img/team.svg" alt="" />
                </div>
                <h3>Enhancing alumni engagement</h3>
                <p>
                  Activate the valuable asset of your institution’s alumni
                  network! Enable alumni to give back to their alma mater
                  through their expertise to students and entrepreneurial
                  projects while also providing a platform for alumni to
                  reconnect and meet.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="card">
              <div class="card-body">
                <div class="card-icon">
                  <img src="../../assets/img/share.svg" alt="" />
                </div>
                <h3>Facilitating mentorship</h3>
                <p>
                  Allow students to navigate internship and job opportunities,
                  find and participate in company projects, network with company
                  representatives or even receive one-on-one mentoring.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="card">
              <div class="card-body">
                <div class="card-icon">
                  <img src="../../assets/img/chart.svg" alt="" />
                </div>
                <h3>Guiding and supporting entrepreneurial venture creation</h3>
                <p>
                  Assist entrepreneurial projects on their journeys towards real
                  business success through mentoring, digital co-working spaces
                  and on-demand interactive learning materials.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="features">
      <div class="container">
        <h2 class="subtitle text-center">Features</h2>
        <div class="row">
          <div class="col-lg-5">
            <mat-accordion class="accordion md-accordion" multi>
              <ng-container *ngFor="let product of products">
                <mat-expansion-panel
                  class="card mat-elevation-z0"
                  hideToggle
                  (opened)="product.isOpen = true"
                  (closed)="product.isOpen = false"
                >
                  <mat-expansion-panel-header class="card-header">
                    <a [ngClass]="{ collapsed: !product.isOpen }">
                      <i class="fas fa-caret-down rotate-icon"></i>
                      <h5>{{ product.title }}</h5>
                    </a>
                  </mat-expansion-panel-header>
                  <div class="card-body">
                    <p>
                      {{ product.description }}
                    </p>
                  </div>
                </mat-expansion-panel>
              </ng-container>
            </mat-accordion>
          </div>
          <div class="col-lg-7">
            <div class="image-gradient text-right">
              <img src="../../assets/img/openup-index.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </body>
</html>
