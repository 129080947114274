import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-demo-snackbar',
  templateUrl: './demo-snackbar.component.html',
  styleUrls: ['./demo-snackbar.component.css']
})
export class DemoSnackbarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
