import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Endpoints } from 'src/app/endpoints';
import { Tutorial } from '../model/tutorial';

@Injectable({
  providedIn: 'root',
})
export class TutorialService {
  constructor(private httpClient: HttpClient) {}

  getOpenupTutorials(): Observable<Array<Tutorial>> {
    return this.httpClient.get<Array<Tutorial>>(Endpoints.TUTORIAL + 'openup');
  }

  getSkillVersumTutorials(): Observable<Array<Tutorial>> {
    return this.httpClient.get<Array<Tutorial>>(
      Endpoints.TUTORIAL + 'skillversum'
    );
  }
}
