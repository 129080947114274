import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutComponent } from './about/about.component';
import { BlogComponent } from './blog/blog.component';
import { BlogEntryComponent } from './blog-entry/blog-entry.component';
import { ProductComponent } from './product/product.component';
import { ResourcesComponent } from './resources/resources.component';
import { TutorialComponent } from './tutorial/tutorial.component';
import { IndexComponent } from './index/index.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SafePipe } from './safe.pipe';
import { NotFoundComponent } from './not-found/not-found.component';
import { MaterialModule } from './material';
import { DemoDialogComponent } from './demo-dialog/demo-dialog.component';
import { DemoSnackbarComponent } from './demo-snackbar/demo-snackbar.component';
import { DemoSnackbarErrorComponent } from './demo-snackbar-error/demo-snackbar-error.component';

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    BlogComponent,
    BlogEntryComponent,
    ProductComponent,
    ResourcesComponent,
    TutorialComponent,
    IndexComponent,
    NavbarComponent,
    FooterComponent,
    SafePipe,
    NotFoundComponent,
    DemoDialogComponent,
    DemoSnackbarComponent,
    DemoSnackbarErrorComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  entryComponents: [DemoDialogComponent],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
