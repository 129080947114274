import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Endpoints } from '../endpoints';
import { Blog } from './model/blog';

@Injectable({
  providedIn: 'root',
})
export class BlogEntryService {
  constructor(private httpClient: HttpClient) {}

  getBlogByUrl(url: string): Observable<Blog> {
    return this.httpClient.get<Blog>(Endpoints.BLOG + url);
  }
}
