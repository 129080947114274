<!DOCTYPE html>
<!-- saved from url=(0025)https://openup.education/ -->
<html lang="en">
<head></head>

<body aria-busy="true" cz-shortcut-listen="true">
<section id="home" class="hero">
  <div class="container">
    <div style="text-align: center;margin-left: 5px" class="col-lg-6">
      <h1 style="  margin-left: 39px;">
         OpenUp to special opportunities!
      </h1>
      <p style="margin-right: 0px;text-align: center" class="descripton-paragrap">
        Check out a wide range of career <br/>management and  development tools available within a single platform!
      </p>
      <div style="width:850px;height:580px;text-align:center;position: absolute;
    z-index: 1;
    top: -55px;
    left: 80%;" class="notebook-background" >
        <iframe width="600" height="380" style="margin-top:80px; margin-left: 45px; "
                src="https://www.youtube.com/embed/GJShDuZEgTs"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
      </div>
    </div>
  </div>
</section>
<section class="hero caret-background">
  <div class="container">
    <div class="d-flex">
      <div class="hero-left text-align-center">
        <h1>
          Career Development Platform!
        </h1>
        <p class="descripton-paragrap extra-padding">
          Through connecting students, alumni, mentors and external practitioners in a vibrant digital hub tailor-made
          to the institution, Openup.education aims to provide all the career development tools to university students
          and their recruiters and mentors in a single platform.
        </p>
        <img src="/assets/img/stairs_renew.PNG"  class="bottom-image" style="width: 350px">

      </div>
    </div>

  </div>
</section>
<section class="hero caret-background">
  <div class="container">
    <div class="d-flex">
      <div class="hero-left text-align-center">
        <h1>
          Internship Period Matchmaking!
        </h1>
        <p class="descripton-paragrap extra-padding">
          Internship matchmaking processes can be conducted continuously and also in a specific period of time.
          Companies announce their proposed positions available for students in a dedicated surface.
        </p>
        <img src="/assets/img/internship_renew.PNG"  class="bottom-image">

      </div>
    </div>

  </div>
</section>

<section class="hero caret-background">
  <div class="container">
    <div class="d-flex">
      <div class="hero-left text-align-center">
        <h1>
          Alumni Management!
        </h1>
        <p class="descripton-paragrap extra-padding">
          The platform provides a surface where the journey of alumni members can be followed through their
          interactions. Alumni can offer mentoring support, organise events, or use actively the feed section by
          engaging the students.
        </p>
        <img src="/assets/img/students_renew.PNG" class="bottom-image" style="width: 350px">

      </div>
    </div>

  </div>
</section>
<section class="hero caret-background">
  <div class="container">
    <div class="d-flex">
      <div class="hero-left text-align-center">
        <h1>
          Mentoring Campaigns!
        </h1>
        <p class="descripton-paragrap extra-padding">
          Mentoring support can be provided by many actors of the platform. Campaigns can be continuous or periodical as
          well, customised for the needs of the client.
        </p>
        <img src="/assets/img/chat_renew.PNG" class="bottom-image" style="width: 350px">

      </div>
    </div>

  </div>
</section>
<section class="hero caret-background">
  <div class="container">
    <div class="d-flex">
      <div class="hero-left text-align-center">
        <h1>
          Start-up Development!
        </h1>
        <p class="descripton-paragrap extra-padding">
          Offering several templates for business development (Business Model Canvas, Value Proposition Canvas, Persona
          Template, etc.), Openup provides a supportive surface for enhancing the entrepreneurial mindset.
        </p>
        <img src="/assets/img/startup.png" class="bottom-image" style="width: 350px;">

      </div>

    </div>

  </div>
</section>
<section id="functions" class="hero ">
  <div class="container">
    <div class="row">
      <h1 class="center-element">
        Functions
      </h1>
    </div>
    <div class="row">
      <div class="col-lg-4 feed-function-element function-element">
        <div class="function-image-content" id="feed-function">
          <img src="/assets/img/feed.png"/>
          <h2>Feed</h2>
        </div>
        <div class="function-description-content">
          <img src="/assets/img/feed_hover.png" width="40%" height="40%" />
          <p class="description-color">Feed</p>
          <div class="function-description-content-details">
            A common surface where every member of the platform is free to post a general message (depending on their visibility settings), a challenge, or a personal idea.
          </div>
        </div>
      </div>
      <div class="col-lg-4 function-element">
        <div class="function-image-content" id="internship-function">
          <img src="/assets/img/work.png"/>
          <h2>Internship</h2>
        </div>
        <div class="function-description-content">
          <img src="/assets/img/work_hover.png"  width="40%" height="40%" />
          <p class="description-color">Internship</p>
          <div class="function-description-content-details">

          Jobs and internship opportunities are available on the platform that will open new horizons in terms of recruiting students from the higher education ecosystems. Compulsory internship matchmaking process makes it an easier process to reach out to students directly.
          </div>
        </div>

      </div>
      <div class="col-lg-4 function-element">
        <div class="function-image-content" id="job-function">
          <img src="/assets/img/job_portal.png"/>
          <h2>Job portal</h2>
        </div>
        <div class="function-description-content">
          <img src="/assets/img/job_portal_hover.png"  width="40%" height="40%" />
          <p class="description-color">Job portal</p>
          <div class="function-description-content-details">
            A common space to search for job positions and to create job opportunities. Via the help of advanced research, the offers can be easily filtered.
          </div>
        </div>

      </div>
      <div class="col-lg-4 function-element">
        <div class="function-image-content">
          <img src="/assets/img/core_facility.png" id="corefacility-function"/>
          <h2>Core Facility</h2>
        </div>
        <div class="function-description-content">
          <img src="/assets/img/core_facility_hover.png"  width="40%" height="40%" />
          <p class="description-color">Core Facility</p>
          <div class="function-description-content-details">
            A matchmaking tool between the resources of partner universities and phD students/researchers of other partner universities. Calls can be published where phD students can apply for using the given resources as a part of their research.
          </div>
        </div>

      </div>
      <div class="col-lg-4 function-element">
        <div class="function-image-content" id="group-function">
          <img src="/assets/img/group.png"/>
          <h2>Group</h2>
        </div>
        <div class="function-description-content">
          <img src="/assets/img/group_hover.png"  width="40%" height="40%" />
          <p class="description-color">Group</p>
          <div class="function-description-content-details">
            A common or private space for groups created for a given circle of people to pursue conversations and share documents among themselves.
          </div>
        </div>
      </div>
      <div class="col-lg-4 function-element">
        <div class="function-image-content" id="skill-function">
          <img src="/assets/img/skill.png"/>
          <h2>Skill development</h2>
        </div>
        <div class="function-description-content">
          <img src="/assets/img/skill_hover.png"  width="40%" height="40%" />
          <p class="description-color">Skill development</p>
          <div class="function-description-content-details">
            The platform provides a well-developed competence development tool for monitoring purposes in terms of skills and competences.
          </div>
        </div>
      </div>
      <div class="col-lg-4 function-element">
        <div class="function-image-content" id="projects-function">
          <img src="/assets/img/project.png"/>
          <h2>Projects</h2>
        </div>
        <div class="function-description-content">
          <img src="/assets/img/project_hover.png"  width="40%" height="40%" />
          <p class="description-color">Projects</p>
          <div class="function-description-content-details">
            A common space which collects all the projects running on the platform. Each project room involves a detailed 10-step guide which is a helpful tool for developing a business idea into a real business opportunity.
          </div>
        </div>
      </div>
      <div class="col-lg-4 function-element">
        <div class="function-image-content" id="elearning-function">
          <img src="/assets/img/elearning.png"/>
          <h2>E-learning</h2>
        </div>
        <div class="function-description-content">
          <img src="/assets/img/elearning_hover.png"  width="40%" height="40%" />
          <p class="description-color">E-learning</p>
          <div class="function-description-content-details">
            Skillversum software is a peer-to-peer e-learning module where the class materials can be easily uploaded with the purpose of transferring knowledge among members.
          </div>
        </div>
      </div>
      <div class="col-lg-4 function-element">
        <div class="function-image-content" id="events-function">
          <img src="/assets/img/event.png"/>
          <h2>Events</h2>
        </div>
        <div class="function-description-content">
          <img src="/assets/img/event_hover.png"  width="40%" height="40%" />
          <p class="description-color">Events</p>
          <div class="function-description-content-details">
            A common space where upcoming events can be followed, and users can get information about the details. With personalised QR code generation, the presence of students at events can be monitored.
          </div>
        </div>
      </div>
      <div class="col-lg-4 function-element">
        <div class="function-image-content" id="course-function">
          <img src="/assets/img/education_2.PNG" width="227px" height="212px"/>
          <h2>Course catalogue</h2>
        </div>
        <div class="function-description-content">
          <img src="/assets/img/education_v2_hover.png"  width="40%" height="40%" />
          <p class="description-color">Course catalogue</p>
          <div class="function-description-content-details">
            Courses can be created on a dedicated surface where every necessary information can be highlighted..
          </div>
        </div>
      </div>
      <div class="col-lg-4 function-element">
        <div class="function-image-content" id="blog-function">
          <img src="/assets/img/blog.PNG" width="227px" height="212px"/>
          <h2>Blogs</h2>
        </div>
        <div class="function-description-content">
          <img src="/assets/img/blog_hover.png"  width="40%" height="40%" />
          <p class="description-color">Blogs</p>
          <div class="function-description-content-details">
            Bogs can be published via a dedicated surface and blogs can be uploaded on multiple languages..
          </div>
        </div>
      </div>
      <div class="col-lg-4 function-element">
        <div class="function-image-content" id="podcast-function">
          <img src="/assets/img/microphone.PNG" width="227px" height="212px"/>
          <h2>Podcast</h2>
        </div>
        <div class="function-description-content">
          <img src="/assets/img/microphone_hover.png"  width="40%" height="40%" />
          <p class="description-color">Podcast</p>
          <div class="function-description-content-details">
            You can publish podcast episodes via adding a direct link and providing additional information as well. .
          </div>
        </div>
      </div>

      <div class="function-element" style=" max-width: 33.333333%;margin-left: auto;margin-right: auto">
        <div class="function-image-content center-element" id="alumni-function">
          <img class="center-element" src="/assets/img/students_renew_2.png"  width="227px" height="212px"/>
          <h2>Alumni management</h2>
        </div>
        <div class="function-description-content center-element">
          <img class="center-element" src="/assets/img/students_renew_2_hover.png"  width="40%" height="40%" />
          <p class="description-color">Alumni management</p>
          <div class="function-description-content-details">
            This software provides an efficient toolset for following the postgradutation journey of the alumni members of your institution..
          </div>
        </div>
      </div>
      <div class="function-element" style=" max-width: 33.333333%;margin-left: auto;margin-right: auto">
        <div class="function-image-content center-element" id="mentoring-function">
          <img class="center-element" src="/assets/img/chatting.png"  width="227px" height="212px"/>
          <h2>Mentoring</h2>
        </div>
        <div class="function-description-content center-element">
          <img class="center-element" src="/assets/img/chatting_hover.png"  width="40%" height="40%" />
          <p class="description-color">Mentoring</p>
          <div class="function-description-content-details">
            A surface is provided where mentors and mentees can meet and start conversation with each other regarding the collaboration details.
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section id="tutorials" class="hero ">
  <div class="container">
    <div class="col-lg-12">
        <h1 class="">
          Tutorials
        </h1>
    </div>

    <div class="col-lg-12 text-align-center">
      <div>
        <h2>Registration</h2>
        <iframe width="280" height="200" style="margin-top:20px; margin-left:auto;margin-right: auto;max-width: 100% "
                src="https://www.youtube.com/embed/r08jpv0fWsY"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
      </div>
    </div>
    <div class="col-lg-12 text-align-center">
      <div>
        <h2>Settings</h2>
        <iframe width="280" height="200" style="margin-top:20px; margin-left:auto;margin-right: auto;max-width: 100% "
                src="https://www.youtube.com/embed/Ln2AZNi--Xc"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
      </div>
    </div>
    <div class="col-lg-12 text-align-center">
      <div>
        <h2>Profile</h2>
        <iframe width="280" height="200" style="margin-top:20px; margin-left:auto;margin-right: auto;max-width: 100% "
                src="https://www.youtube.com/embed/gQe74jFyVCI"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
      </div>
    </div>
    <div class="col-lg-12">
      <a
        href="https://www.openup.education/tutorial"
        class="btn btn-outline-primary btn-rounded btn-lg waves-effect waves-light float-right"
      >More</a>
    </div>
  </div>

</section>
<section id="applications" class="hero ">
  <div class="container ">
    <div class="col-lg-12 center-element text-align-center">
      <h1 >
        Applications
      </h1>
    </div>
    <div class="row">
      <div class="col-lg-4 col-sm-12 ">

        <div class="row application-background" style=" margin: 1px;">
          <div class="col-lg-12 text-align-center">
            <h1>
              Universities
            </h1>
          </div>
          <div class="col-lg-4">
            <img src="/assets/img/feed.png" width="100%" (click)="scroll('feed-function')"/>
          </div>
          <div class="col-lg-4">
            <img src="/assets/img/event.png" width="100%"  (click)="scroll('event-function')"/>
          </div>
          <div class="col-lg-4">
            <img src="/assets/img/job_portal.png" width="100%"  (click)="scroll('job-function')"/>
          </div>
          <div class="col-lg-4">
            <img src="/assets/img/students.png" width="100%"  (click)="scroll('functions')"/>
          </div>
          <div class="col-lg-4">
            <img src="/assets/img/work.png" width="100%"  (click)="scroll('job-function')"/>
          </div>
          <div class="col-lg-4">
            <img src="/assets/img/group.png" width="100%"  (click)="scroll('group-function')"/>
          </div>
          <div class="col-lg-4">
            <img src="/assets/img/project.png" width="100%"  (click)="scroll('projects-function')"/>
          </div>
          <div class="col-lg-4">
            <img src="/assets/img/chatting.png" width="100%"  (click)="scroll('functions')"/>
          </div>
          <div class="col-lg-4">
            <img src="/assets/img/elearning.png" width="100%"  (click)="scroll('elearning-function')"/>
          </div>
        </div>
      </div>
        <div class="col-lg-4 col-sm-12 ">
          <div class="row application-background" style=" margin: 1px;">
            <div class="col-lg-12 text-align-center">
              <h1>
                Alliances
              </h1>
            </div>
            <div class="col-lg-4">
              <img src="/assets/img/feed.png" width="100%"  (click)="scroll('feed-function')"/>
            </div>
            <div class="col-lg-4">
              <img src="/assets/img/chatting_v2.PNG" width="100%"  (click)="scroll('functions')"/>
            </div>
            <div class="col-lg-4">
              <img src="/assets/img/microscope.PNG" width="100%"  (click)="scroll('functions')"/>
            </div>
            <div class="col-lg-4">
              <img src="/assets/img/project.png" width="100%"  (click)="scroll('projects-function')"/>
            </div>
            <div class="col-lg-4">
              <img src="/assets/img/group.png" width="100%"  (click)="scroll('group-function')"/>
            </div>
            <div class="col-lg-4">
              <img src="/assets/img/event.png" width="100%"  (click)="scroll('event-function')"/>
            </div>
            <div class="col-lg-4">
              <img src="/assets/img/microphone.PNG" width="90%"  (click)="scroll('podcast-function')"/>
            </div>
            <div class="col-lg-4">
              <img src="/assets/img/elearning.png" width="100%"  (click)="scroll('elearning-function')"/>
            </div>
            <div class="col-lg-4">
              <img src="/assets/img/education_2.PNG" width="100%"  (click)="scroll('course-function')"/>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-12 ">
          <div class="row application-background" style=" margin: 1px;">
            <div class="col-lg-12 text-align-center">
              <h1>
                Clusters
              </h1>
            </div>
            <div class="col-lg-4">
              <img src="/assets/img/feed.png" width="100%"  (click)="scroll('feed-function')"/>
            </div>
            <div class="col-lg-4">
              <img src="/assets/img/project.png" width="100%"  (click)="scroll('projects-function')"/>
            </div>
            <div class="col-lg-4">
              <img src="/assets/img/elearning.png" width="100%"  (click)="scroll('elearning-function')"/>
            </div>
            <div class="col-lg-4">
              <img src="/assets/img/chatting_v2.PNG" width="100%" (click)="scroll('functions')"/>
            </div>
            <div class="col-lg-4">
              <img src="/assets/img/work.png" width="100%" (click)="scroll('job-function')"/>
            </div>
            <div class="col-lg-4">
              <img src="/assets/img/project.png" width="100%" (click)="scroll('projects-function')"/>
            </div>
            <div class="col-lg-4">
              <img src="/assets/img/event.png" width="100%" (click)="scroll('event-function')"/>
            </div>
            <div class="col-lg-4">
              <img src="/assets/img/blog.PNG" width="100%" (click)="scroll('blog-function')"/>
            </div>
            <div class="col-lg-4">
              <img src="/assets/img/microphone.PNG" width="90%" (click)="scroll('podcast-function')"/>
            </div>
          </div>
        </div>
    </div>
  </div>
</section>
<section id="aboutus" class="about">
  <div class="container">
    <h2 class="text-center">About Us</h2>
    <p class="text-center" style="font-weight: bold">
      We would be happy to hear from you. Contact us via the details below or schedule a demo and let one of our
      colleagues guide you through the platform.
    </p>
    <div class="d-flex justify-content-center">

      <ul class="list-unstyled mb-0">
        <li>
          <a href="tel:+36209725080" style="color:white;font-size: 30px"
          ><i class="fas fa-phone-alt mr-3"></i>+36 20 972 5080</a
          >
        </li>
        <li>
          <a href="mailto:info@openup.education" style="color:white;font-size: 30px">
            <i class="fas fa-envelope mr-3"></i>
            info@openup.education
          </a>
        </li>
        <li>
          <a style="color:white;font-size: 30px"
             href="https://www.google.com/maps/place/P%C3%A9cs,+Vasv%C3%A1ri+P%C3%A1l+u.+4,+7622/@46.0735924,18.2362097,17z/data=!3m1!4b1!4m5!3m4!1s0x4742b174ad6205bb:0x4ac80e7dd30ff84e!8m2!3d46.0735924!4d18.2383984"
          ><i class="fas fa-map-marker-alt mr-3"></i>1 H-7622 Pécs Vasvári
            Pál utca 4.</a
          >
        </li>
      </ul>
    </div>
    <div class="text-center mt-4">
      <a
        (click)="openDialog()"
        class="btn btn-outline-primary btn-rounded btn-lg waves-effect waves-light"
      >Learn more</a
      >
    </div>
  </div>
</section>
</body>
</html>
