import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {DemoDialogComponent} from '../demo-dialog/demo-dialog.component';
import {ViewportScroller} from '@angular/common';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css'],
})
export class IndexComponent implements OnInit {
  constructor(private titleService: Title, private scroller: ViewportScroller, public dialog: MatDialog) {}

  ngOnInit(): void {
    this.titleService.setTitle('Home');
  }
  openDialog(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    this.dialog.open(DemoDialogComponent, dialogConfig);
  }

  scroll(element: string): void {
    this.scroller.setOffset([200, 200]);
    this.scroller.scrollToAnchor(element);
  }
}
