import { HttpResponse } from '@angular/common/http';
import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DemoSnackbarErrorComponent } from '../demo-snackbar-error/demo-snackbar-error.component';
import { DemoSnackbarComponent } from '../demo-snackbar/demo-snackbar.component';
import { DemoService } from './demo.service';
import { DemoRequest } from './model/demo-request';

@Component({
  selector: 'app-demo-dialog',
  templateUrl: './demo-dialog.component.html',
  styleUrls: ['./demo-dialog.component.css'],
})
export class DemoDialogComponent {
  @ViewChild('form') form: FormGroup;
  demo = new DemoRequest();

  constructor(
    public dialogRef: MatDialogRef<DemoDialogComponent>,
    private snackBar: MatSnackBar,
    private demoService: DemoService
  ) {}

  onSubmit() {
    let response$ = this.demoService.requestDemo(this.form.value);
    this.demo = new DemoRequest();
    this.dialogRef.close();
    response$.subscribe((response: HttpResponse<any>) => {
      if (response.status === 200) {
        this.openSuccessSnackBar();
      } else {
        this.openErrorSnackBar();
      }
    });
  }

  openSuccessSnackBar() {
    this.snackBar.openFromComponent(DemoSnackbarComponent, {
      duration: 3000,
    });
  }

  openErrorSnackBar() {
    this.snackBar.openFromComponent(DemoSnackbarErrorComponent, {
      duration: 3000,
    });
  }
}
