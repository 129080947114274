import {
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  DomSanitizer,
  SafeResourceUrl,
  Title,
} from '@angular/platform-browser';
import { Observable, Subscription } from 'rxjs';
import { Tutorial } from './model/tutorial';
import { TutorialService } from './service/tutorial-service.service';

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.css'],
})
export class TutorialComponent implements OnInit {
  $openupTutorials: Observable<Array<Tutorial>>;
  $skillversumTutorials: Observable<Array<Tutorial>>;

  constructor(private service: TutorialService, private titleService: Title) {}

  ngOnInit(): void {
    this.$openupTutorials = this.service.getOpenupTutorials();
    this.$skillversumTutorials = this.service.getSkillVersumTutorials();

    this.titleService.setTitle('Tutorial');
  }
}
