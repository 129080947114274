import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoints } from '../endpoints';
import { DemoRequest } from './model/demo-request';

@Injectable({
  providedIn: 'root',
})
export class DemoService {
  constructor(private httpClient: HttpClient) {}

  requestDemo(demoRequest: DemoRequest) {
    return this.httpClient.post(Endpoints.DEMO, demoRequest, {
      observe: 'response',
    });
  }
}
