<!DOCTYPE html>
<html lang="en">
  <head></head>
  <body aria-busy="true" cz-shortcut-listen="true">
    <ng-container *ngIf="blogEntry">
      <section class="resources">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="card mb-6">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div>
                      <h4>
                        {{ blogEntry.title }}
                        <small
                          >-
                          {{ blogEntry.createdBy }}
                          {{ blogEntry.createdDate }}</small
                        >
                      </h4>
                      <p>{{ blogEntry.description }}</p>
                    </div>
                  </div>
                  <div class="d-flex">
                    <img
                      style="margin-left: auto; margin-right: auto"
                      src="{{ blogEntry.thumbnailImage }}"
                      class="img-fluid"
                    />
                  </div>
                  <div class="d-flex align-items-center">
                    <div [innerHTML]="blogEntry.content"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ng-container>
  </body>
</html>
