export class DemoRequest {
  constructor(
    public firstName: string = null,
    public lastName: string = null,
    public organization: string = null,
    public jobTitle: string = null,
    public phoneNumber: string = null,
    public email: string = null,
    public country: string = 'Hungary',
    public message: string = null
  ) {}
}
